export const ROOT_API_PATH = process.env.REACT_APP_API_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export const PATH_FIND_MANY_COMPANY = `${ROOT_API_PATH}/api/company/find-many-company`;
export const PATH_FIND_MANY_CERTIFICATES = `${ROOT_API_PATH}/api/certificates`;
export const PATH_FIND_MANY_EXPERTISES = `${ROOT_API_PATH}/api/ekspertise/find-many-ekspertise`;
export const PATH_FIND_MANY_DEPARTMENTS = `${ROOT_API_PATH}/api/department/find-many-department`;
export const PATH_FIND_MANY_BRANCH = `${ROOT_API_PATH}/api/branch-list/find-many-branch`;
export const PATH_FIND_MANY_COUNTRIES = `${ROOT_API_PATH}/api/country/find-many-country`;
export const PATH_FIND_MANY_CITY = `${ROOT_API_PATH}/api/city/find-many-city`;
export const PATH_BRANCHES_LIST = `${ROOT_API_PATH}/api/branch-lists`;
export const PATH_BRANCHES = `${ROOT_API_PATH}/api/branches`;

export const PATH_GOOGLE_AUTH_CALLBACK = `${ROOT_API_PATH}/api/auth/google/callback`;
export const PATH_GOOGLE_CONNECT = `${ROOT_API_PATH}/api/connect/google`;

export const PATH_MICROSOFT_AUTH_CALLBACK = `${ROOT_API_PATH}/api/auth/microsoft/callback`;
export const PATH_MICROSOFT_CONNECT = `${ROOT_API_PATH}/api/connect/microsoft`;

export const PATH_MICROSOFT_ZA_AUTH_CALLBACK = `${ROOT_API_PATH}/api/auth/microsoft_za/callback`;
export const PATH_MICROSOFT_ZA_CONNECT = `${ROOT_API_PATH}/api/connect/microsoft_za`;

export const PATH_REFRESH_TOKEN = `${ROOT_API_PATH}/api/token/refresh`;
export const PATH_LOGOUT = `${ROOT_API_PATH}/api/token/logout`;

export const PATH_COUNTRIES = `${ROOT_API_PATH}/api/countries`;
export const PATH_CITY = `${ROOT_API_PATH}/api/cities`;
export const PATH_COMPANY = `${ROOT_API_PATH}/api/companies`;
export const PATH_DEPARTMENTS = `${ROOT_API_PATH}/api/departments`;
export const PATH_DEV_CREATE_DEFAULT_DATA = `${ROOT_API_PATH}/api/devs/create-default-data`;
export const PATH_EXPERTISES = `${ROOT_API_PATH}/api/ekspertises`;
export const PATH_EXPERTISES_OLD = `${ROOT_API_PATH}/api/expertise`;
export const PATH_KNOWLEDGE_BASES = `${ROOT_API_PATH}/api/knowledge-bases`;
export const PATH_ORGANIZATIONS = `${ROOT_API_PATH}/api/organizations`;
export const PATH_POSTS = `${ROOT_API_PATH}/api/posts`;
export const PATH_USERS = `${ROOT_API_PATH}/api/users`;
export const PATH_USERS_DEVS = `${ROOT_API_PATH}/api/devs/users`;
export const PATH_USERS_ME = `${ROOT_API_PATH}/api/users/me`;
export const PATH_USERS_ORH_CHART = `${ROOT_API_PATH}/api/devs/users/org-chart`;
export const PATH_USERS_MENTION = `${ROOT_API_PATH}/api/devs/users-mention`;
export const PATH_USERS_ME_UPDATE = `${ROOT_API_PATH}/api/devs/users/updateMe`;
export const PATH_USER_DETAILS = `${ROOT_API_PATH}/api/devs/users/me`;
export const PATH_CATEGORIES = `${ROOT_API_PATH}/api/about-categories`;
export const PATH_SUB_CATEGORIES = `${ROOT_API_PATH}/api/about-sub-categories`;
export const PATH_SUB_SUB_CATEGORIES = `${ROOT_API_PATH}/api/about-sub-sub-categories`;
export const PATH_CATEGORIES_LIST = `${ROOT_API_PATH}/api/about-categories-list`;
export const PATH_COMMENTS = `${ROOT_API_PATH}/api/comments`;
export const PATH_LIKES = `${ROOT_API_PATH}/api/likes`;
export const PATH_FORUM_CATEGORIES = `${ROOT_API_PATH}/api/forum-categories`;
export const PATH_FORUM_TOPICS = `${ROOT_API_PATH}/api/forum-topics`;
export const PATH_FORUM_TOPICS_CLOSE = `${ROOT_API_PATH}/api/forum-topics/close`;
export const PATH_UPLOAD = `${ROOT_API_PATH}/api/upload`;
export const PATH_BANNER = `${ROOT_API_PATH}/api/banner`;
export const PATH_ADVERTS = `${ROOT_API_PATH}/api/adverts`;
export const PATH_ADVERTS_EDIT_MY = `${ROOT_API_PATH}/api/adverts`;
export const PATH_ADVERTS_DELETE_MY = `${ROOT_API_PATH}/api/adverts/delete-only-my`;
export const PATH_LOCALES = `${ROOT_API_PATH}/api/i18n/locales`;
export const PATH_BUSINESS_APPS = `${ROOT_API_PATH}/api/business-apps`;
export const PATH_BUSINESS_APPS_PRIVATE = `${ROOT_API_PATH}/api/business-app-privates`;
export const PATH_FAVOURITE_BUSINESS_APPS = `${ROOT_API_PATH}/api/favourite-business-apps`;
export const PATH_DORNACH_INTERVENTION = `${ROOT_API_PATH}/api/dornach-intervention`;
export const PATH_ADVERTS_CATEGORY = `${ROOT_API_PATH}/api/advert-categories`;
export const PATH_CURRENCIES = `${ROOT_API_PATH}/api/currencies`;
export const PATH_LANGUAGES_LIST = `${ROOT_API_PATH}/api/languages-list`;
export const PATH_EXPORT_BRANCHES = `${ROOT_API_PATH}/api/import-export-entries/content/export/contentTypes`;
export const PATH_NEWS_NOTIF_LANGUAGES_LIST = `${ROOT_API_PATH}/api/news-languages`;
